<template>
    <div :class="classname" ref="trackVisibility"></div>
 </template>
 
 <script>
    export default {
       name: "VisibilityObserver",
 
       props: {
          visibilityOptions: {
             type: Object,
             default: () => {
                return {
                   root: null, // check visibility compared to browser viewport
                   rootMargin: '100%  0px',
                   threshold: 0,
                }
             }
          },
 
          classname: {
             type: String,
             default: 'my-loader-height',
          }
 
       },
 
       data() {
          return {
             observer: null,
 
             isIntersecting: false,
          }
       },
 
 
       mounted() {
          this.observer = new IntersectionObserver(this.handleVisibility, this.visibilityOptions);
 
          let target = this.$refs.trackVisibility;
 
          this.observer.observe(target);
       },
 
       methods: {
          handleVisibility(entries, observer) {
             let target = entries[0]; // we have only one target to observe
             if (target && target.isIntersecting) {
                this.$emit('on-visible');
                this.isIntersecting = target.isIntersecting;
             }
             if (target && !target.isIntersecting) {
                this.$emit('on-hidden');
             }
          }
       },
 
       destroyed() {
          if (this.observer)
             this.observer.disconnect();
       },
    }
 </script>
 
 <style scoped>
    .my-loader-height {
       /*height: 200px;*/
    }
 </style>
 