<template>
  <div class="row">
    <h2 class="gray-text f-18">Location</h2>
    <div style="width: 100%">
      <iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" class="rounded"
        :src="'https://maps.google.com/maps?q=' +
          address.latitude +
          ',' +
          address.longitude +
          '&t=&z=15&ie=UTF8&iwloc=&output=embed'
          ">
        <!-- src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" -->
        <a href="https://www.maps.ie/distance-area-calculator.html">
          measure distance on map
        </a>
      </iframe>
    </div>
    <div class="my-3 col-md-12">
      <label class="form-label " for="address">{{ label }}</label>
      <div class="position-relative 2">
        <address-auto-complete :classname="classname" :default-auto-complete-text="address.address"
          @update:addressData="buildAddressData($event)" />
      </div>
    </div>

    <div class="col-md-6">
      <div class="mb-3">
        <label for="city_id" class="form-label fw-medium">
          City / Town
          <span class="text-danger">*</span>
        </label>
        <input v-model="address.city" name="address-field-city" type="text" required
          class="form-control w-100 radius-5 h-100 f-14 px-3 border-1 rounded-pill" id="city_id" placeholder="City / Town" />
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-3">
        <label for="country_id" class="form-label fw-medium">
          Country
          <span class="text-danger">*</span>
        </label>
        <input v-model="address.country" name="address-field-country" type="text" required
          class="form-control w-100 radius-5 h-100 f-14 px-3 border-1 rounded-pill" id="country_id" placeholder="Country" />
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-3">
        <label for="state_id" class="form-label fw-medium">
          State/Province
          <span class="text-danger">*</span>
        </label>
        <input v-model="address.state" name="address-field-state" type="text"
          required class="form-control w-100 radius-5 h-100 f-14 px-3 border-1 rounded-pill" id="state_id" placeholder="State / Province" />
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-3">
        <label for="zip_id" class="form-label fw-medium">
          ZIP
          <!--               <span class="text-danger"></span>-->
        </label>

        <input v-model="address.zipcode" name="address-field-zipcode" type="text" required
          class="form-control w-100 radius-5 h-100 f-14 px-3 border-1 rounded-pill" id="zip_id" placeholder="ZIP" />
        <input type="hidden" v-model="address.place_id">
      </div>
    </div>

    <div class="col-md-12">
      <slot name="submit-btn"></slot>
    </div>
  </div>
</template>

<script>
import AddressAutoComplete from "./AddressAutoComplete.vue";

export default {
  name: "AddressFields",

  props: {
    label: {
      type: String,
      default: "Address",
    },

    classname: {
      type: String,
      default: "form-control w-100 radius-5 h-100 f-14 px-3 border-1 rounded-pill",
    },

    modelValue: {
      type: Object,
      default: () => {
        return {
          address: null,
          city: null,
          state: null,
          zip_code: null,
          country: null,
          place_id: null,
        };
      },
    },
  },
  components: {
    AddressAutoComplete,
  },

  data() {
    return {
      address: {
        latitude: 10.305385,
        longitude: 77.923029,
        address: null,
        city: null,
        state: null,
        zipcode: null,
        country: null,
        place_id: null
      },
    };
  },

  methods: {
    mapObject(obj, other) {
      for (let key in obj) {
        obj[key] = other[key];
      }
    },
    buildAddressData(event) {
      // console.log(event?.placeResultData?.formatted_address);
      // console.log(event.addressData);
      let address = {
        longitude: event?.addressData?.longitude,
        latitude: event?.addressData?.latitude,
        address: event?.placeResultData?.formatted_address,
        country: event?.addressData?.country,
        city: event?.addressData?.locality,
        state: event?.addressData?.administrative_area_level_1,
        zipcode: event?.addressData?.postal_code,
        place_id: event?.placeResultData?.place_id
      };
      // console.log(address);
      this.mapObject(this.address, address);
    },
  },

  watch: {
    modelValue: {
      handler: function (currentObj) {
        this.mapObject(this.address, currentObj);
        // if (currentObj) {
        // }
      },
      deep: true,
      immediate: true,
    },

    address: {
      handler: function (currentObj) {
        this.$emit("update:modelValue", currentObj);
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped></style>