<template>

<multiselect
    v-model="value"
    :multiple="multiple"
    :options="options"
    :preserve-search="preserveSearch"
    label="title"
    track-by="title"
    :class="className"
    :searchable="searchable"
    @search-change="$emit('search-change', $event)"
    @select="changeOccur($event)"
>
<template #afterList>
    <visibilityObserver @on-visible = "$emit('fetchMore')"/>
</template>
</multiselect>
                        

                  
</template>

<script>
import Multiselect from "vue-multiselect";
import visibilityObserver from "./visibilityObserver.vue"
    export default {
        components: {
    Multiselect,
    visibilityObserver,
   
  },
  data ( ){
   return {
    value : this.vModel,
   }
  },
  props:{
    vModel:{
      type: String,
      default:null
    },
    multiple: {
        type: Boolean,
        default: false
    },
    options: {
        type: Array,
    default: () => [] 

    },
    preserveSearch: {
        type: Boolean,
        default: true
    },
    searchable: {
        type: Boolean,
        default: true
    },
    className: {
        type: String,
        default: "custom-dropdown"
    },

  },
  watch:{
    value:{
      handler: function(newVal){
        this.$emit('input',newVal)
      }
    }
  },
  methods:{
    changeOccur(event){
        this.$emit ('onChange', {event} );
    }
  }
    }
</script>

<style lang="scss" scoped>

</style>